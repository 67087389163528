import Vue from 'vue'

import JsonBox from './../views/box-json';
import PlaceOrderButton from './../views/place-order-button';
import ProductTable from './../views/table-product';
import BoxPaymentResult from './../views/box-payment-result';
import ListBank from './../views/list-bank';


Vue.component(JsonBox.name, JsonBox)
Vue.component(PlaceOrderButton.name, PlaceOrderButton)
Vue.component(ProductTable.name, ProductTable)
Vue.component(BoxPaymentResult.name, BoxPaymentResult)
Vue.component(ListBank.name, ListBank)




