import * as http from './../js/service';

export default {
    namespaced: true,
    state: {
        cart: {
            items: [

            ],
            totalAmount: 0,
            totalQuantity: 0,
        }
    },
    actions: {
        addToCart({ state, commit, dispatch }, request) {
            let url = '/order/addToCart'

            http.post(url, request).then(res => {
                state.cart = res;
            })

        },
        removeToCart({ state, commit, dispatch }, request) {
            let url = '/order/removeToCart'

            http.post(url, request).then(res => {
                state.cart = res;
            })

        },
        getCart({ state, commit, dispatch }, request) {
            let url = '/order/getCartItems'

            return http.get(url).then(res => {
                state.cart = res;
                return res

            })

        },
    }

}