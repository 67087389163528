<template>
  <div>
    thetik
    <v-btn
      color="primary"
      @click="thetik2()"
    >Try</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    thetik() {
      let data = {
        paymentAmount: 500,
        cur: "THB",
        outTradeNo: "2014072300007148",
        outTradeTime: "2014-07-23 17:14:09",
        notifyUrl: "https://www.google.com/",
        subject: "Iphone6 16G",
        body: "Iphone6 16G Red",
      };
      this.generateSign(data).then((res) => {
        er("res", res);
      });
    },
    async thetik2() {
      let data = {
        paymentAmount: 500,
        cur: "THB",
        outTradeNo: "2014072300007148",
        outTradeTime: "2014-07-23 17:14:09",
        notifyUrl: "https://www.google.com/",
        subject: "Iphone6 16G",
        body: "Iphone6 16G Red",
      };
      let res = await this.generateSign(data);
      er(res);
    },
    // generateSign() {
    //   let data = {
    //     paymentAmount: 500,
    //     cur: "THB",
    //     outTradeNo: "2014072300007148",
    //     outTradeTime: "2014-07-23 17:14:09",
    //     notifyUrl: "https://www.google.com/",
    //     subject: "Iphone6 16G",
    //     body: "Iphone6 16G Red",
    //   };

    //   let payload = Object.assign({}, this.publicPaylod, { data });
    //   er(payload);
    //   return;

    //   let formData = new FormData();
    //   formData.append("text", JSON.stringify(payload));
    //   //   formData.append("text", payload);

    //   let url = "https://mp-api.flashfin.com/signature/test-request-sign";
    //   this.$http.uploadFile(url, formData, {
    //     disabledBaseUrl: true,
    //     headers: {
    //       Authorization: this.privateKey,
    //     },
    //   });
    // },
  },
};
</script>