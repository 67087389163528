<template>
  <div>

    <v-app-bar
      app
      class="nav"
    >
      <router-link
        class="d-inline-block mx-3"
        v-for="(item,index) in mainLinks"
        :key="index"
        :to="item.target"
      >
        {{item.text}}
      </router-link>

      <div class="flex-grow-1"></div>
      <v-badge
        color="red"
        v-if="state.cart.totalQuantity"
        :content="state.cart.totalQuantity"
      >
        <router-link to="/cart">
          <v-icon>mdi-cart</v-icon>
        </router-link>
      </v-badge>
      <router-link
        to="/cart"
        v-else
      >
        <v-icon>mdi-cart</v-icon>
      </router-link>

      <div class="ml-5">
        <router-link to="/order">รายการสั่งซื้อ </router-link>

        <a
          class="mx-3"
          href="javascript:void(0);"
          @click="clickedLogout()"
          v-if="showButtonLogout"
        >ล็อกเอ้าท์</a>

      </div>

    </v-app-bar>

    <v-divider></v-divider>
  </div>

</template>
  
  <script>
import { logOut } from "./../js/authorization";

export default {
  name: "app-bar",
  components: {},
  $route: {
    handler(to) {},
    immediate: true,
    deep: true,
  },
  data: () => ({
    forceRecomputeCounter: 0,

    mainLinks: [
      {
        target: "product",
        text: "Product",
      },
      // {
      //   target: "cart",
      //   text: "Cart",
      // },
      // {
      //   target: "payment",
      //   text: "Payment",
      // },
    ],
  }),
  methods: {
    clickedLogout() {
      er("fff");

      logOut();
      this.$router.push("/login");
    },
  },
  computed: {
    state() {
      return this.$store.state.order;
    },
    showButtonLogin() {
      if (this.isloggedIn) {
        return false;
      } else {
        return true;
      }
    },
    showButtonRegister() {
      if (this.isloggedIn) {
        return false;
      } else {
        return true;
      }
    },
    showButtonLogout() {
      if (this.isloggedIn) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
  
  
  <style>
tbody > tr.highlight {
  outline: none;
  -webkit-box-shadow: inset 0 0 6px #0966b8;
  box-shadow: inset 0 0 6px #0966b8;
}

.table-product tbody tr {
  cursor: pointer;
}

.router-link-active {
  /* background-color: rgb(171, 205, 92); */
  font-weight: 700;
  text-decoration: underline !important;
}

.nav a {
  text-decoration: none;
}
</style>
  