<template>
  <div>
    <!-- <v-btn :to="'/payment'">จ่ายเงิน</v-btn> -->
    <v-simple-table
      dense
      class="table-item"
    >
      <thead>
        <tr>
          <th>No.</th>
          <th>Name</th>
          <th>Price</th>
          <th>จำนวน</th>
        </tr>
      </thead>
      <tbody>

        <tr
          v-for="(item,index) in products"
          :key="item.id"
          @click="$emit('input', item)"
        >
          <td>{{index + 1}}</td>
          <td>{{item.product.subject}}</td>
          <td>{{item.product.price}}</td>
          <td>
            <v-icon
              @click="removeToCart(item.product)"
              v-if="canChangeQty"
            >mdi-minus</v-icon>

            <span class="mx-2">{{item.quantity}}</span>

            <v-icon
              @click="addToCart(item.product)"
              v-if="canChangeQty"
            >mdi-plus</v-icon>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "table-product",
  props: {
    canChangeQty: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    addToCart(product) {
      this.$store.dispatch("order/addToCart", product);
    },
    removeToCart(product) {
      this.$store.dispatch("order/removeToCart", product);
    },
  },
  computed: {},
};
</script>