<template>
  <v-app>

    <!-- <div class="nav">
      <router-link
        class="d-inline-block mx-3"
        v-for="(item,index) in mainLinks"
        :key="index"
        :to="item.target"
      >
        {{item.text}}
      </router-link>

      <div class="flex-grow-1"></div>

      <router-link to="/cart">
        <v-icon>mdi-cart</v-icon>
      </router-link>
    </div> -->

    <!-- <v-app-bar app>
      <router-link
        class="d-inline-block mx-3"
        v-for="(item,index) in mainLinks"
        :key="index"
        :to="item.target"
      >
        {{item.text}}
      </router-link>

      <div class="flex-grow-1"></div>

      <router-link to="/cart">
        <v-icon>mdi-cart</v-icon>{{state.cartItems.length}}
      </router-link>

      <div class="ml-2">
        <router-link
          v-if="showButtonLogin"
          to="/login"
          class="mx-1"
        >ล็อกอิน</router-link>
        <router-link
          v-if="showButtonRegister"
          to="/register"
          class="mx-1"
        >สมัครบัญชี</router-link>

        <a
          href="javascript:void(0);"
          @click="clickedLogout()"
          v-if="showButtonLogout"
        >ล็อกเอ้าท์</a>
      </div>
    </v-app-bar> -->

    <!-- <v-divider></v-divider> -->
    <app-bar v-if="!showFullScreen"></app-bar>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

  </v-app>

</template>

<script>
import { logOut } from "./js/authorization";
import AppBar from "./views/app-bar.vue";

export default {
  name: "App",
  components: {
    AppBar,
  },
  $route: {
    handler(to) {},
    immediate: true,
    deep: true,
  },
  data: () => ({
    forceRecomputeCounter: 0,

    mainLinks: [
      {
        target: "product",
        text: "Product",
      },
      // {
      //   target: "cart",
      //   text: "Cart",
      // },
      // {
      //   target: "payment",
      //   text: "Payment",
      // },
    ],
  }),
  created() {},
  methods: {
    clickedLogout() {
      logOut();
      this.$router.push("/login");
    },
  },
  computed: {
    showFullScreen() {
      if (this.$route.meta && this.$route.meta.fullscreen) {
        return true;
      }
      return false;
    },
    state() {
      return this.$store.state.order;
    },
    showButtonLogin() {
      if (this.isloggedIn) {
        return false;
      } else {
        return true;
      }
    },
    showButtonRegister() {
      if (this.isloggedIn) {
        return false;
      } else {
        return true;
      }
    },
    showButtonLogout() {
      if (this.isloggedIn) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>


<style>
tbody > tr.highlight {
  outline: none;
  -webkit-box-shadow: inset 0 0 6px #0966b8;
  box-shadow: inset 0 0 6px #0966b8;
}

.table-product tbody tr {
  cursor: pointer;
}

.router-link-active {
  /* background-color: rgb(171, 205, 92); */
  font-weight: 700;
  text-decoration: underline !important;
}

.nav a {
  text-decoration: none;
}
</style>
