import {
  isloggedIn,
  getToken,
  currentRole,

} from "./../js/authorization";
import { UserRole } from "./../js/constants";
import { Server } from "./../js/constants";

import Vue from "vue";

// register the plugin on vue
import Toasted from "vue-toasted";
Vue.use(Toasted, {
  iconPack: "mdi",
});

const globalOptions = {
  duration: 5000,
  containerClass: "v-application",
  className: "text-left",
  keepOnHover: true,
  position: "bottom-right",
  action: {
    class: ["white--text"],
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

export default {
  data() {
    return {
      env_SERVER: process.env.VUE_APP_SERVER,
      server: Server,
      forceRerenderRequired: 0,
      flashBaseUrl: "https://test-pay-openapi.flashfin.com",
      publicPayload: {
        appKey: "8048898229171344691248",
        charset: "UTF-8",
        signType: "RSA2",
        time: "2020-07-10 12:07:07",
        version: "1.0",
      },
      privateKey:
        "MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC1SuAtTPj3NUbM21OAQgoJTN8VbNkNhe1cAIyoHew7VwSq41HPxG13nJpWaIpXKQy9zRDDxX/FxlaODy1Qn/vdIcCSwb5KD2zr96nCpAbnyH1O6yAMZQ136PYINWh4P2BB9sOiK2mrTLEIFJ2CCkcRVV7MZ7i2ZKHqos2e9vg6kTaZChz6XcRyaOdhmCXHXq8NjjjAPYm3vrRnnhAp+VJFMAbKQdb0d0aKdT+I8Cz+mg1+Kw39ckWB+pBsYkcU79q/HcfgS15eiz7VUd4HF+G04K1sB3Y2oL4vqbBmrGj81lCUr9W2vcyoSN2+eeEtEMoJpEiJcbnheeGLBD/OK6kRAgMBAAECggEAFu1BLHlvgtPaAE48YRv46PQvwiXhzTQCsH/hSeF+3oJCtNnKntvYfCfx3LubwyoN0nL8i8WOQxSyHYUN1gRldbGvCOA3kGYjADRx44ua0/Um47QYeXjOGzv64U7fx1U8dxsd4oRa+K7aNItm3+ss0aRaOL9lPKu9Tk8IcfIJsl4zsq8lqyvIvUfyOn1kaPWCN51X/Tq7NxPxfEpbVpERR5ARnvtj4DT4YiKLAqFKDcxisRsGqNIeVAxub+cdYDuPV+sVa5hMDzudb8P1YAIIzZbXEb1DWaaYVToUs4/6sylC0bqgqQHCNBFyTg6Y+4FcxI7W64IJuWVv3jTwbJYjgQKBgQD9jC9pXPAkmuqgIxdRCvp9TJey7xDAkzAfUN8B9Y3xoYz3YHa7iqldSupaF6L5jaNynAzeqr1tfmW90+qRfXgCpfZBTwuYUj4STBHBk7M9ve0DbMSl2UtJTcb3URxvv59mSxKec10mDHZ1t5B0w2K/eLiYlL/E9vApsXq2iIFQowKBgQC3C8crY4+anqNb7Ph2o95psCxnWAKEZFX6ZPWtT7ZBPMzIxVB0gb9QUX2kDBpmX3eUCranyocn4qGYZGd8CVqy7NkHWXlcDgqPBrx+Lngi/OSxo7LXkGyfg2rRX4yRW7usLApctfWONmV7z4hXWBAiGvDE4x8D4imOOUPlJdVWuwKBgQDG2dYpVVmF6/XNiBf2WJzh/lsPW82Qqx9XwC5WtAYSFvie3wnfxj1UTLgF1nncLMiez2KMk+4i3Lngqj5SwsuHkC/VQrA+U2oZIY3fev5dkwI66GhGBmi0YgqE1xltTyUtrf05RhMtDyMs6b2ixcZBkuV7zVWlnWH34Rjz5ZU97wKBgQCaVLvgwVjarNZfMzVqEk/8HCXUHEhDB7XWIDljnsmzx2eB/5sOIDcFqS+dmPbJScSwdTf70K6T2mkU69K0qc5m/Xsezzmbe9s7LBrQ6K0yErdfbv3i8kojU9JEJaOHCyj4yUkTUfdvqD14Xko+Gmm/16cY2cXUqGMRHwc3tOZi8QKBgQCiO4KKFfa3dcWck5N7cRpE7tQ4Hng+332S93Me4EoiQbuCO+bnlgGIdUX8myheM+e2QZ0leTCtGCkhSfWmQW5sjzrvW5xToKlmRAY8esK+zvzlD7U9t6+/ctt3vHVoCJDnd/FKgk6GnkpLlvneZwATm0Qn712VCm67lnGA3kIRIg==",
    };
  },
  methods: {
    thisShouldTriggerRecompute() {
      this.forceRecomputeCounter++;
    },
    hasPermission(permission) {
      if (!permission) {
        return null;
      }

      if (permission.includes("*")) {
        return true;
      }

      let currentRole = getToken().role;

      let result = permission
        .map((x) => x.toLowerCase())
        .includes(currentRole.toLowerCase());
      return result;
    },
    showAlertSuccess(message) {
      let defaultMessage = "Data has been changed successfully";
      const options = { ...globalOptions };
      options.type = "success";
      options.icon = "mdi-check-circle";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    showAlertError(message) {
      let defaultMessage = "Error";
      const options = { ...globalOptions };
      options.type = "error";
      options.icon = "mdi-alert";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    showAlertInfo(message) {
      let defaultMessage = "just info";
      const options = { ...globalOptions };
      options.type = "info";
      options.icon = "mdi-information";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    clearAllAlert() {
      let myToast = Vue.toasted.clear();
    },
    catchError(err) {
      let message = "";
      if (typeof err === "string") {
        message = err;
      } else if (err && err.message) {
        message = err.message;
      } else if (err && err.errors) {
        let arr = _.toArray(err.errors);
        message = arr.join(", ");
      }

      // this.showAlertError(message);
    },
    hiddenHtmlScrollbar(val) {
      document.querySelector("html").classList.toggle("hidden-scrollbar", val);
    },
    generateSign(data) {

      let payload = Object.assign({}, this.publicPayload, { data });

      // er(payload);
      // return;

      let formData = new FormData();
      formData.append("text", JSON.stringify(payload));

      let url = "https://mp-api.flashfin.com/signature/test-request-sign";
      return this.$http.uploadFile(url, formData, {
        disabledBaseUrl: true,
        headers: {
          Authorization: this.privateKey,
        },
      }).then(res => {
        return {
          sign: res.data.sign,
          publicPayload: this.publicPayload
        }
      });
    },
  },
  computed: {
    isServerProduction() {
      return process.env.VUE_APP_SERVER === Server.Production;
    },
    isProductionEnvironment() {
      return [Server.Production, Server.Staging, Server.Test].includes(
        process.env.VUE_APP_SERVER
      );
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    displayName() {
      return getToken().displayName;
    },
    userName() {
      er('dddd', getToken().userName);

      return getToken().userName;
    },
    expiration() {
      return getToken().expiration;
    },


    isloggedIn,
    currentRole,
  },
};
