<template>
  <div>
    <h3>
      1. Create Order
    </h3>
    <!-- 
    <v-row>
      <v-col class="pl-10">
        <table-product v-model="selectedProduct"></table-product>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col
        cols="12"
        class="pl-10"
      >

        <list-bank
          v-model="bankCode"
          v-if="this.state.cart.totalAmount > 0 "
        ></list-bank>

        <v-radio-group
          v-model="instalmentTerm"
          v-if="bankCode"
        >
          <template v-slot:label>
            <div style="width:140px">
              แผนการผ่อนชำระ
            </div>
          </template>

          <v-radio
            v-for="item in plans"
            :key="item.value"
            :value="item.value"
          >
            <template v-slot:label>
              <v-row>
                <v-col>
                  {{item.text}} x ฿{{payPerMonth(item.value) | currency(2)}}/mo.
                </v-col>
              </v-row>

            </template>
          </v-radio>
        </v-radio-group>
      </v-col>

    </v-row>

    <v-row>
      <v-col class="pl-10">
        <h5>Order No. : {{ payment?.data?.outTradeNo}}</h5>
        <place-order-button
          class="mt-1"
          @clicked-submit="createOrder()"
          :loading="loading"
          :disabled="!canPlaceOrder"
        ></place-order-button>
      </v-col>
    </v-row>

    <v-divider class="mt-2"></v-divider>

    <div v-if="payment.message == successText">
      <h3>
        2. Payment
      </h3>
      <input
        v-on:focus="$event.target.select()"
        class="ml-2 px-1"
        type="text"
        id="thetik"
        ref="divText"
        style="background-color: gold; border: 1px solid black; border-radius:3px;width:700px"
        readonly
        :value="payment.deeplinkUrl"
      />
      <v-btn
        @click="copy()"
        class="ml-1"
        text
      >{{ copyTextButton }}</v-btn>

    </div>
    <v-divider class="mt-2"></v-divider>

    <div v-if="showResultPayment">
      <h3>3. Query the payment results</h3>
      <box-payment-result
        ref="paymentResult"
        :prefixUrl="prefixUrl"
        :tradeNo="payment.data?.tradeNo"
        :outTradeNo="payment.data?.outTradeNo"
      ></box-payment-result>

    </div>

  </div>
</template>

<script>
import { toQueryString } from "@/js/custom";
import { getProducts, getBanks } from "@/js/dropdown-service";
import ListBank from "./list-bank.vue";

//   moment().format('YYYY-MM-DD hh:mm:ss')
// '2022-09-15 02:22:06'
export default {
  data() {
    return {
      prefixUrl: "/PayWithInstallment",
      plans: [
        {
          value: "3",
          text: "3 months",
        },
        {
          value: "6",
          text: "6 months",
        },
        {
          value: "10",
          text: "10 months",
        },
      ],
      successText: "Request Succeeded",
      payment: {},
      bankCode: null,
      instalmentTerm: null,
      copyTextButton: "Copy",
      loading: false,
    };
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    createOrder() {
      this.payment = {};
      let url = `${this.prefixUrl}/CreateOrder`;
      let payload = _.cloneDeep(this.state.cart);
      payload.bankCode = this.bankCode;
      payload.instalmentTerm = this.instalmentTerm;

      this.loading = true;
      this.$http
        .post(url, payload)
        .then((res) => {
          if (res.message == this.successText) {
            this.payment = res;

            if (res.data.redirectUrl) {
              // window.open(res.data.redirectUrl, "_blank").focus();

              var link = document.getElementById("link");
              link.addEventListener("click", () => {
                var w = window.open("", "MyWindow");
                // Ajax call
                setTimeout(() => {
                  w.location.href = res.data.redirectUrl;
                }, 100);
              });
              link.click();
            }

            if (this.showResultPayment) {
              this.$nextTick(() => {
                this.$refs.paymentResult.getResult();
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    payPerMonth(value) {
      let interestRate = 0.7;
      let amount = this.state.cart.totalAmount;

      let interestPermonth = amount * (interestRate / 100);
      let permonth = amount / Number(value) + interestPermonth;
      return permonth;
    },
    initialTextCopy() {
      this.copyTextButton = "Copy";
    },
    copy() {
      this.$refs.divText.focus();
      document.execCommand("copy");
      this.copyTextButton = "Copied";
    },
  },
  computed: {
    state() {
      return this.$store.state.order;
    },
    showResultPayment() {
      if (
        this.payment.data &&
        this.payment.data.tradeNo &&
        this.payment.data.outTradeNo
      ) {
        return true;
      }
      return false;
    },
    canPlaceOrder() {
      let result = false;
      if (this.bankCode && this.instalmentTerm) {
        result = true;
      }
      return result;
    },
  },
};

function mockup() {
  return {
    sign: "SvMcbH+DeVysKUv9mR/rr0bSmLoJ++Evn8I1fHjrBtqHf8m6yw/RgkLNjCOtzw3Bl+qjVyvjXDGrPnHn5RMR3GiONfvINpbtKP/mef4Bt6FLQVIYHr/+kZ3f34JV/B7/KuIREMGtc9Y/xpWJFMX6AG8cc5wJWpNRRZggxFeWbUFOYI0EGMpYScM1o5PelDBUM5kclLXBaQ3bMY8OZ48FxFR4ZfSn5JqjDtR/ZCKng7MNRhAzPB2/vj7gSHM9oO8+KBX/JqIxGR77iNrYp4PQE0zbMxXlJwWPiXwAiJC/LtrE9Mm7V4q7ITec3r5Rj8/YNHRXiT2Fae7F/r7GlyvuDA==",
    code: 0,
    message: "Request Succeeded",
    data: {
      outTradeNo: "3541149580277376",
      tradeNo: "220915040851298616",
      tradeTime: "2022-09-15 05:15:55",
      paymentAmount: 100,
      cur: "THB",
      redirectUrl:
        "https://test-cashier-api.flashfin.com/Authorize/0e45c1f4c01142c8960d411224079389",
    },
  };
}
</script>

  