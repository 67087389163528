

import { fakePrefix, fakeAbbr } from '../_helpers/fake-url';


function endpoint(options) {
    let opts = { ...options }//clone object
    let url = opts.url;

    let body = opts.body;
    let fakeIndex = url.indexOf(fakePrefix);
    if (fakeIndex < 0) {
        throw new Error('url not contains fake prefix')
    }

    let startRealUrl = url.indexOf('/')
    let realUrl = url.substring(startRealUrl)//

    let fakeContent = url.substring(0, startRealUrl).match(/\((.*)\)/);
    let fakeUrl = fakeContent[1].split('|')[1];//content in rounded brakets

    //login
    if (fakeUrl === fakeAbbr.login) {
        return {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3NDcxZDU2Ny05MzI0LTQ1NjgtODQ3MC0wNDQ4MGI3ZWUyYjAiLCJqdGkiOiJkMzI1NDQyOS1lNzFmLTQxMDYtODYzYi1iOTlhODcyZmY2NDciLCJpYXQiOiIxNTgyMjc0MTMyIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImFkbWluIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI3NDcxZDU2Ny05MzI0LTQ1NjgtODQ3MC0wNDQ4MGI3ZWUyYjAiLCJuYmYiOjE1ODIyNzQxMzIsImV4cCI6MTU4NzQ1ODEzMiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo0NDM2MyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NDQzNjMifQ.dyIe90R8vMHsYyH9YsDZEy0xphEXYjYY6JNIhw2YRZQ",
            "userName": null,
            "displayName": "นายadmin admin",
            "role": "ADMIN",
            "expiration": "2020-04-21T08:35:32Z",
            "physicianId": null,
            "employeeId": "70e750f6-014b-42f3-fe6a-08d7167f5bc2"
        }
    }
    //change password
    if (fakeUrl === fakeAbbr.changePassword) {
        return 'Your password has been changed successfully'
    }

    //dropdown
    if (fakeUrl.includes('dropdown')) {
        return DropdownService.endpoint(url, opts)
    }

    //profile
    switch (fakeUrl) {
        case fakeAbbr.line.checkVerified: return LineService.checkVerified(realUrl, opts);
        case fakeAbbr.line.generateToken: return LineService.generateToken(realUrl, opts);
    }
}



export default { endpoint };


