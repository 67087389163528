<template>
  <div>
    <NavAnonymouse></NavAnonymouse>

    <v-row class="mt-10 pa-10">
      <v-col cols="2">
        <v-text-field
          label="User name"
          v-model="model.userName"
          @keyup.enter="submit()"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="submit()"
        >Submit</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { logIn } from "@/js/authorization";
import { MockupPassword } from "@/js/constants";
import NavAnonymouse from "./anonymouse-page.vue";

export default {
  components: {
    NavAnonymouse,
  },
  data() {
    return {
      model: {
        userName: null,
      },
    };
  },
  methods: {
    submit() {
      let payload = _.cloneDeep(this.model);
      payload.password = MockupPassword;

      let url = "/account/register";

      this.$http
        .post(url, payload)
        .then((res) => {
          this.$router.push("/");
        })
        .catch((er) => {})
        .finally(() => {});
    },
  },
};
</script>