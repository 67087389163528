<template>
  <div>
    <!-- <v-btn :to="'/payment'">จ่ายเงิน</v-btn> -->

    <table-product
      :products="state.cart.items"
      :canChangeQty="true"
    ></table-product>

    <div class="my-3 subtitle-2">
      Total : ฿{{state.cart.totalAmount | currency(2)}}
    </div>
    <v-btn
      to="/payment"
      color="primary"
      v-if="state.cart.totalQuantity > 0 && showChangeQuantity"
    >จ่ายเงิน</v-btn>
  </div>
</template>

<script>
import TableProduct from "./table-product.vue";

export default {
  components: {
    "table-product": TableProduct,
  },
  props: {
    showChangeQuantity: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getCart();
  },
  methods: {
    getCart() {
      let promise = this.$store.dispatch("order/getCart");
    },
    addToCart(product) {
      this.$store.dispatch("order/addToCart", product);
    },
    removeToCart(product) {
      this.$store.dispatch("order/removeToCart", product);
    },
  },
  computed: {
    state() {
      return this.$store.state.order;
    },
  },
};
</script>