<template>
  <div>
    <div>
    </div>
    <box-json :model="resultPayment"></box-json>
  </div>
</template>

<script>
import { toQueryString } from "@/js/custom";

export default {
  name: "box-payment-result",
  props: {
    prefixUrl: String,
    tradeNo: {
      type: String,
    },
    outTradeNo: {
      type: String,
    },
  },
  data() {
    return {
      interval: null,
      resultPayment: {},
    };
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getResult() {
      if (!this.tradeNo && !this.outTradeNo) {
        return;
      }

      let url = `${this.prefixUrl}/GetResultPayment?`;

      url += toQueryString({
        tradeNo: this.tradeNo,
        outTradeNo: this.outTradeNo,
      });

      this.interval = setInterval(() => {
        this.$http.post(url).then((res) => {
          this.resultPayment = res.data;
        });
      }, 3000);
    },
    stopGet() {},
  },
};
</script>