<template>
  <div>
    <v-card
      v-for="(order,index) in orders"
      :key="index"
      class="my-2 elevation-7"
    >
      <v-card-text>
        <span class="orange--text">
          {{order.outTradeNo}} - {{order.status}}

        </span>
        <table-product
          :products="order.items"
          :canChangeQty="false"
        ></table-product>

        <div>
          <div>
            รวม ฿{{order.totalAmount}}
          </div>
          <div>
            วันที่สั่งซื้อ {{order.placeOrderDate}}
          </div>
          <div>
            วันที่ชำระเงิน {{order.paidDate}}
          </div>
          <div>
            ช่องทางชำระเงิน {{order.paymentMethod}}
          </div>

          <div>
            tradeStatus {{order.tradeStatus}}
          </div>

        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orders: [],
    };
  },
  created() {
    let url = "/order/orders";
    this.$http.get(url).then((res) => {
      this.orders = res.items;
    });
  },
};
</script>