import Vue from 'vue';
import Vuex from 'vuex';

import order from './order.module';



Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        order,
    }
});
