<template>
  <div>
    <table-product
      :products="state.cart.items"
      :canChangeQty="false"
    ></table-product>

    <div class="my-3 subtitle-2">
      Total : ฿{{state.cart.totalAmount | currency(2)}}
    </div>

    <div class="nav">

      <router-link
        class="d-inline-block mx-3"
        v-for="(item,index) in links"
        :key="index"
        :to="item.target"
      >
        {{item.text}}
      </router-link>
    </div>
    <v-divider></v-divider>
    <!-- <router-view></router-view> -->

    <!-- <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :cart="obj"
      />
    </router-view> -->

    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component
          :cart="obj"
          :is="Component"
          :key="route.path"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Cart from "./cart.vue";

export default {
  components: {
    Cart,
  },
  data() {
    return {
      links: [
        {
          target: "pay-by-qrcode",
          text: "QR Code",
        },
        {
          target: "pay-by-credit-card",
          text: "Credit Card",
        },
        {
          target: "pay-by-installment",
          text: "Installment",
        },
        // {
        //   target: "pay-by-flashLink",
        //   text: "Flash Link",
        // },
        {
          target: "pay-by-deeplink",
          text: "DeepLink",
        },
        {
          target: "pay-by-online-debit",
          text: "Online debit",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("order/getCart");
  },
  computed: {
    state() {
      return this.$store.state.order;
    },
  },
};
</script>