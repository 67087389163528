<template>
  <div>
    <v-btn
      small
      color="primary"
      @click="clicked()"
      :disabled="!(!loading && !disabled)"
    >Place Order </v-btn>
    <span
      class="ml-2"
      v-if="loading"
    >Loading ...</span>
  </div>
</template>

<script>
export default {
  name: "place-order-button",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clicked() {
      this.$emit("clicked-submit");
    },
  },
};
</script>