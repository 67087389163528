
export const MockupPassword = "hx$8B*w@"

export const Server = {
  Local: "local",
  Development: "development",
  Test: "test",
  Staging: "staging",
  Production: "production",
};

export class DateFormat {
  static diffThaiYear = 543;
  static beautyDateFormat = "DD/MM/YYYY";
  static dateStandardFormat = "YYYY-MM-DD";

  static beautyPeriodFormat = "MM/YYYY";
  static PeriodStandardFormat = "YYYY-MM";
}

export const DATE_PICKER_TYPE = {
  DATE: "date",
  MONTH: "month",
};

export const ServiceType = {
  PM: "PM",
  MA: "MA",
  Demo: "Demo",
  Extenal: "Extenal",
};

export const UserRole = {
  ADMIN: "ADMIN,73ba0c5a-71a8-473d-9699-4b538490c033",
};

export const PriorityRoles = [UserRole.MANAGER, UserRole.ADMIN];

export const Status = {
  PENDING: "PENDING",
  NEW: "NEW",
  แจ้งสืบทรัพย์: "แจ้งสืบทรัพย์",
  เจอทรัพย์: "เจอทรัพย์",
  ไม่เจอทรัพย์: "ไม่เจอทรัพย์",
  ยึดสำเร็จ: "ยึดสำเร็จ",
  ยึดไม่สำเร็จ: "ยึดไม่สำเร็จ",
  รอยืนยันปิดบัญชี: "รอยืนยันปิดบัญชี",
  ปิดบัญชี: "ปิดบัญชี",
  ชะลอการบังคับคดี: "ชะลอการบังคับคดี",
  ถอนการยึดอายัด: "ถอนการยึดอายัด",
};

export const BlockCode = {
  // B1: "B1",
  // B2: "B2",
  // BA: "BA",
  // BD: "BD",
  // BS: "BS",
  // CP: "CP",
  IA: "IA",
  LE: "LE",
};

export const StatusFile = {
  FAIL: "fail",
  PENDING: "pending",
  UPLOADED: "uploaded",
};

export const InputType = {
  dropdown: "dropdown",
  dropdownMultiple: "dropdown-multiple",
  text: "text",
  checkBox: "checkbox",
  radio: "radio",
  password: "password",
  textArea: "text-area",
  datePicker: DATE_PICKER_TYPE.DATE,
  monthPicker: DATE_PICKER_TYPE.MONTH,
  timePicker: "time-picker",
  section: "section",
  offset: "offset",
  label: "label",
  button: "button",
  rowSetting: "row-setting",
  begin_end_date: "begin_end_date",
  begin_end_period: "begin_end_period",
  dropdownRelate: "dropdown-relate",
  address: "address-block",
  lineConnect: "line-connect",
};

export const KeywordUIDialog = {
  job: "job",
  installation: "installation",
  returnMachine: "returnMachine",
  createOncall: "createOncall",
  editActionDate: "editActionDate",
  serviceDemo: "serviceDemo",
  servicePM: "servicePM",
  serviceMA: "serviceMA",
  serviceExternal: "serviceExternal",
};

export const WrapType = {
  alertSuccess: "alert-success",
};

export const MasterMode = {
  add: "add",
  edit: "edit",
};

export const DialogSize = {
  small: "small",
  medium: "medium",
  fullScreen: "full-screen",
};

export const Sorting = {
  ascending: "ascending",
  descending: "descending",
};

export const ProductType = {
  Machine: "machine",
  Modality: "modality",
};

export const NamePrefix = {
  otherId: "a8c35b9e-b3dd-4ba5-8871-be08c492d323",
};
