<template>
  <div>
    <pre>{{JSON.stringify(model, undefined, 4)}}</pre>
  </div>
</template>


<script>
export default {
  name: "box-json",
  props: {
    model: Object,
  },
};
</script>