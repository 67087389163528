<template>
  <div>
    <v-radio-group v-model="innerValue">
      <template v-slot:label>
        <div style="width:140px">
          จ่ายเงินผ่านแอพธนาคาร
        </div>

      </template>

      <v-radio
        v-for="bank in banks"
        :key="bank.bankCode"
        :value="bank.bankCode"
      >
        <template v-slot:label>
          <img
            :src="'/img/bank/' +bank.bankNameEn +'.jpg'"
            :alt="bank.bankNameEn"
            width="50"
            class="ml-3 mr-3"
          >
          {{bank.bankNameEn}}
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { getBanks } from "@/js/dropdown-service";
export default {
  name: "list-bank",
  props: {
    value: [String, Object],
  },
  data() {
    return {
      banks: [],
    };
  },
  created() {
    getBanks().then((res) => {
      this.banks = res.items;
    });
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>