import Vue from 'vue'
import VueRouter from 'vue-router'
import { isloggedIn, getToken } from './../js/authorization';
import UtilsMixins from './../_mixins/utils-mixins';



import HomeView from '../views/HomeView.vue'
import Test from '../views/test.vue'
import PayByQRCode from '../views/pay-by-qrcode.vue'
import PayByCreditCard from '../views/pay-by-credit-card.vue'
import PayByInstallment from '../views/pay-by-installment'
import PayByDeepLink from '../views/pay-by-deeplink'
import PayByOnlineDebit from '../views/pay-by-online-debit'
import Product from '../views/product'
import Cart from '../views/cart'
import Payment from '../views/payment'
import Login from '../views/login'
import Register from '../views/register'
import Order from '../views/order'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/product',
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requireAuth: false, permission: ["*"], fullscreen: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requireAuth: false, permission: ["*"], fullscreen: true }
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    meta: { requireAuth: true, permission: ["*"] }
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: { requireAuth: true, permission: ["*"] }
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    meta: { requireAuth: true, permission: ["*"] }
  },
  // {
  //   path: '/payment',
  //   name: 'payment',
  //   component: Payment,
  // },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: { requireAuth: true, permission: ["*"] },

    children: [
      {
        path: '/pay-by-qrcode',
        name: 'PayByQRCode',
        component: PayByQRCode,
        meta: { requireAuth: true, permission: ["*"] }
      },
      {
        path: '/pay-by-credit-card',
        name: 'PayByCreditCard',
        component: PayByCreditCard,
        meta: { requireAuth: true, permission: ["*"] }
      },
      {
        path: '/pay-by-installment',
        name: 'PayByInstallment',
        component: PayByInstallment,
        meta: { requireAuth: true, permission: ["*"] }
      },
      {
        path: '/pay-by-deeplink',
        name: 'PayByDeepLink',
        component: PayByDeepLink,
        meta: { requireAuth: true, permission: ["*"] }
      },
      {
        path: '/pay-by-online-debit',
        name: 'PayByOnlineDebit',
        component: PayByOnlineDebit,
        meta: { requireAuth: true, permission: ["*"] }
      },
    ]
  },
  // {
  //   path: '/pay-by-qrcode',
  //   name: 'PayByQRCode',
  //   component: PayByQRCode
  // },
  // {
  //   path: '/pay-by-credit-card',
  //   name: 'PayByCreditCard',
  //   component: PayByCreditCard
  // },
  // {
  //   path: '/pay-by-installment',
  //   name: 'PayByInstallment',
  //   component: PayByInstallment
  // },
  // {
  //   path: '/pay-by-deeplink',
  //   name: 'PayByDeepLink',
  //   component: PayByDeepLink
  // },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.name === 'login') {
    next();
    return;
  }

  let expired = isExpireToken()
  if (expired) {
    next('/login');
    return;
  }

  if (to.meta.requireAuth && !isloggedIn()) {
    UtilsMixins.methods.showAlertError("Please sign in.")
    next('/login');
    return;
  }

  if (UtilsMixins.methods.hasPermission(to.meta.permission)) {
    next()
  } else {
    UtilsMixins.methods.showAlertError("You don't have enough permission to access this page.")
    next("/")
  }
})

function isExpireToken() {
  let obj = getToken();
  let { expiration } = obj;
  if (new Date(expiration) < new Date()) {
    return true
  } else {
    return false
  }
}
