<template>
  <div>
    <div>

    </div>
    <!-- <table-product v-model="selectedProduct"></table-product> -->

    <v-row>
      <v-col
        v-for="product in products"
        :key="product.id"
        product
        cols="4"
      >
        <v-card>
          <v-card-text class="pa-5">
            <div>
              <div>{{product.subject}}</div>
              <div>฿{{product.price}}</div>

              <v-btn
                class="mt-1"
                text
                color="primary"
                @click="addToCart(product)"
              >Add to cart</v-btn>
            </div>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import { getProducts } from "@/js/dropdown-service";
export default {
  data() {
    return {
      selectedProduct: null,
      products: [],
    };
  },

  created() {
    this.$store.dispatch("order/getCart");

    getProducts().then((res) => {
      this.products = res.items;
    });
  },
  methods: {
    addToCart(product) {
      this.$store.dispatch("order/addToCart", product);
    },
  },
};
</script>