import { get } from "./service";



export function getProducts() {
  let items = [
    {
      "id": "869de2b7-6dcf-40aa-8d9b-d4f557d39c52",
      "subject": "Vitamin A",
      "body": "Vitamin A",
      "price": 100
    },
    {
      "id": "dc874bee-3a4f-4d45-940c-fa1685fe5b02",
      "subject": "Vitamin B",
      "body": "Vitamin B",
      "price": 200
    },
    {
      "id": "66a933fd-952f-409f-9fa4-c728082b48d1",
      "subject": "Vitamin C",
      "body": "Vitamin C",
      "price": 300
    }
  ]

  return Promise.resolve({
    items,
  });
}

export function getBanks() {
  let items = [
    {
      "bankCode": "004",
      "bankNameEn": "KBank",
      "bankNameFullName": "Kasikorn Bank"
    },
    {
      "bankCode": "014",
      "bankNameEn": "SCB",
      "bankNameFullName": "Siam Commercial Bank"
    }
  ]

  return Promise.resolve({
    items,
  });
}
