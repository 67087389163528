<template>
  <div>
    <h3>
      1. Create Order
    </h3>

    <v-row>
      <v-col class="pl-10">
        <h5>Order No. : {{ payment?.data?.outTradeNo}}</h5>
        <place-order-button
          class="mt-1"
          @clicked-submit="createOrder()"
          :loading="loading"
          :disabled="false"
        ></place-order-button>
      </v-col>
    </v-row>

    <v-divider class="mt-2"></v-divider>

    <div v-if="payment.message == successText">
      <h3>
        2. Payment
      </h3>
      <img
        v-if="payment.data.qrImage"
        :src="'data:image/gif;base64, ' + payment.data.qrImage"
        alt=""
        width="200"
        height="200"
      >
      <v-card-text>
        <pre>
        Sucess：5239620007270264
        Fail：5596886582263266
        OTP : 123456
        ที่เหลือใส่อะไรก็ได้ค่ะ
        </pre>
      </v-card-text>

    </div>
    <v-divider class="mt-2"></v-divider>

    <div v-if="showResultPayment">
      <h3>3. Query the payment results</h3>

      <box-payment-result
        ref="paymentResult"
        :prefixUrl="prefixUrl"
        :tradeNo="payment.data?.tradeNo"
        :outTradeNo="payment.data?.outTradeNo"
      ></box-payment-result>
    </div>
    <button id="link"></button>
  </div>
</template>

<script>
import { toQueryString } from "@/js/custom";
import { getProducts } from "@/js/dropdown-service";

//   moment().format('YYYY-MM-DD hh:mm:ss')
// '2022-09-15 02:22:06'
export default {
  data() {
    return {
      prefixUrl: "/PayWithCreditCard",
      successText: "Request Succeeded",
      payment: {},
      copyTextButton: "Copy",
      loading: false,
    };
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    createOrder() {
      this.payment = {};
      let url = `${this.prefixUrl}/CreateOrder`;
      let payload = _.cloneDeep(this.state.cart);

      this.loading = true;
      this.$http
        .post(url, payload)
        .then((res) => {
          if (res.message == this.successText) {
            this.payment = res;

            if (res.data.redirectUrl) {
              // window.open(res.data.redirectUrl, "_blank").focus();

              var link = document.getElementById("link");
              link.addEventListener("click", () => {
                var w = window.open("", "MyWindow");
                // Ajax call
                setTimeout(() => {
                  w.location.href = res.data.redirectUrl;
                }, 100);
              });
              link.click();
            }

            if (this.showResultPayment) {
              this.$nextTick(() => {
                this.$refs.paymentResult.getResult();
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    state() {
      return this.$store.state.order;
    },
    showResultPayment() {
      if (
        this.payment.data &&
        this.payment.data.tradeNo &&
        this.payment.data.outTradeNo
      ) {
        return true;
      }
      return false;
    },
  },
};

function mockup() {
  return {
    sign: "SvMcbH+DeVysKUv9mR/rr0bSmLoJ++Evn8I1fHjrBtqHf8m6yw/RgkLNjCOtzw3Bl+qjVyvjXDGrPnHn5RMR3GiONfvINpbtKP/mef4Bt6FLQVIYHr/+kZ3f34JV/B7/KuIREMGtc9Y/xpWJFMX6AG8cc5wJWpNRRZggxFeWbUFOYI0EGMpYScM1o5PelDBUM5kclLXBaQ3bMY8OZ48FxFR4ZfSn5JqjDtR/ZCKng7MNRhAzPB2/vj7gSHM9oO8+KBX/JqIxGR77iNrYp4PQE0zbMxXlJwWPiXwAiJC/LtrE9Mm7V4q7ITec3r5Rj8/YNHRXiT2Fae7F/r7GlyvuDA==",
    code: 0,
    message: "Request Succeeded",
    data: {
      outTradeNo: "3541149580277376",
      tradeNo: "220915040851298616",
      tradeTime: "2022-09-15 05:15:55",
      paymentAmount: 100,
      cur: "THB",
      redirectUrl:
        "https://test-cashier-api.flashfin.com/Authorize/0e45c1f4c01142c8960d411224079389",
    },
  };
}
</script>

  